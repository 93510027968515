function notify(notification, status = 'info') {
  $("body").append("<div class='alert-"+status+" messageBar alert'>"+notification+"</div>");
  $(".messageBar").last().delay(3000).fadeOut();
}

$(document).on("turbolinks:load", function(){
  if ($('#account').length) {

    $('.change').click(function() {
      formGroup = $(this).parent()
      $(this).hide()
      formGroup.find('.submit').show()
      formGroup.find('input').attr('disabled', false)
    })

    $('form').on('ajax:success', function(event) {
      data = event.originalEvent.detail[0]
      if (data["alert"]) {
        notify(data["alert"],'danger')
      } else if (data["notice"]) {
        notify(data["notice"])
        if ($(this).parent().hasClass('avatar')) {
          $(this).find('img')[0].src = $(this).find('img').attr('src') + "?" +  new Date().getTime();
          $(this).removeClass('spinner')
        } else {
          $(this).find('.change').show()
          $(this).find('.submit').hide()
          $(this).find('.form-group input').attr('disabled', true)
        }
      }
    })

    $('.avatar input[type=file]').change(function() {
      $(this).parents('form').addClass('spinner')
      $(this).parents('form').find('.submit').click()
    });
  }
})
