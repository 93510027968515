function userPreviewAvatar() {
  var preview = $('.avatar img')[0];
  var file    = document.querySelector('input[type=file]').files[0];
  var reader  = new FileReader();

  reader.onloadend = function () {
    preview.src = reader.result;
  }

  if (file) {
    reader.readAsDataURL(file);
  } else {
    preview.src = "";
  }
}

$(document).on("turbolinks:load", function(){
  if ($('#user-settings').length) {
    $('#user_avatar').change(function() {
      userPreviewAvatar();
    });
  }
})
